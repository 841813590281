import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Recaptcha from "react-google-recaptcha";
import { serialize } from 'dom-form-serializer'
import { useIntl } from "gatsby-plugin-intl"
import { Row, Col } from 'antd';
import Img from "gatsby-image"

const RECAPTCHA_KEY = '6LfKEJgUAAAAALedUzHJ_1FhibI0U-0V0uV1bZYF';// process.env.SITE_RECAPTCHA_KEY;

function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const Contact = () => {
    const image = useStaticQuery(graphql`
        query {
            headboard: file(relativePath: { eq: "headboard-cedrenchante.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `)

    const intl = useIntl()

    const [alertMessage, setAlertMessage] = useState("");

    const ref = React.createRef("recaptcha");

    const handleSubmit = event => {
        event.preventDefault()
        const form = event.target;
        const data = serialize(form)
 
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...data
            })
        })
        .then(res => {
            if (res.ok) {
                return res
            } else {
                throw new Error('Network error')
            }
        })
        .then(() => {
            form.reset()
            setAlertMessage(intl.formatMessage({ id: "CONTACT.NOTIFICATION_MESSAGE_SENT" }))
        })
        .catch(err => {
            console.error(err)
            setAlertMessage(intl.formatMessage({ id: "CONTACT.NOTIFICATION_MESSAGE_SENT_ERROR" }))
        })
    };

    return (
        <Layout page='contact' titles={[intl.formatMessage({ id: "BREADCRUMB.CONTACT" })]}>
            <SEO title={intl.formatMessage({ id: "CONTACT.TITLE" })} keywords={[`Contact`, `Elevage`, `Cedrenchante`, `chiens`, `chats`, `chiots`, `chatons`, `berger des shetland`, `chihuahua`, `epagneul tibetain`, `british shorthair`]}/>
            <div className="container">
                <div style={{margin: '30px 0'}}>
                    <Img fluid={image.headboard.childImageSharp.fluid} style={{ width:"100%" }} alt="Elevage du Cèdre Enchanté"/>
                </div>
                <Row type="flex" justify="center" align="top" gutter={24}>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                        <div>
                            <p><b>{intl.formatMessage({ id: "CONTACT.TEXT_RESPONSABLE" })}</b></p>
                            <p>{intl.formatMessage({ id: "CONTACT.TEXT_ADDRESS" })}</p>
                            <p>{intl.formatMessage({ id: "CONTACT.TEXT_ADDRESS_2" })}</p>
                        </div>
                        <p>{intl.formatMessage({ id: "CONTACT.TEXT_PHONE" })}</p>
                        <p>{intl.formatMessage({ id: "CONTACT.TEXT_MOBILE_PHONE" })}</p>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                        <form className="Form" name="contact" method="post" onSubmit={handleSubmit} action="/" data-netlify-recaptcha="true" netlify="true">
                            <noscript>
                                <p>This form won’t work with Javascript disabled</p>
                            </noscript>
                            {alertMessage && (
                                <div className="Form--Alert">{alertMessage}</div>
                            )}
                            <Row type="flex" justify="center" align="top" gutter={24}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <label className="Form--Label">
                                        <input type="text" name="name" placeholder={intl.formatMessage({ id: "CONTACT.LABEL_LASTNAME" })} className="Form--Input Form--InputText" required/>
                                        <span>{intl.formatMessage({ id: "CONTACT.LABEL_LASTNAME" })}</span>
                                    </label>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <label className="Form--Label">
                                        <input type="text" name="email" placeholder={intl.formatMessage({ id: "CONTACT.LABEL_EMAIL" })} className="Form--Input Form--InputText" required />
                                        <span>{intl.formatMessage({ id: "CONTACT.LABEL_EMAIL" })}</span>
                                    </label>
                                </Col>
                            </Row>
                            <Row type="flex" justify="center" align="top">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <label className="Form--Label">
                                        <textarea name="message" placeholder={intl.formatMessage({ id: "CONTACT.LABEL_MESSAGE" })} rows="10" className="Form--Input Form--Textarea Form--InputText" required />
                                        <span>{intl.formatMessage({ id: "CONTACT.LABEL_MESSAGE" })}</span>
                                    </label>
                                </Col>
                            </Row>
                            <div data-netlify-recaptcha="true"></div>
                            <Recaptcha
                                ref={ref}
                                sitekey={RECAPTCHA_KEY}
                            />
                            <input type="submit" value={intl.formatMessage({ id: "CONTACT.BUTTON_SEND" })} className="ant-btn Form--SubmitButton"/>
                        </form>
                    </Col>
                </Row>
                <div style={{ width: '100%' }}>
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d342717.11272631836!2d0.24851223196844133!3d47.84971597063103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e2ef174a21e6b3%3A0x69a92f47972ea31e!2zTGUgQ8OoZHJlIEVuY2hhbnTDqQ!5e0!3m2!1sfr!2sus!4v1552749009595"  height="300" frameBorder="0" style={{ width: '100%', border: "4px double #f2f2f2" }} allowFullScreen></iframe>
                </div>
            </div>                
        </Layout>
    );
};

export default Contact;